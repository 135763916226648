import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Logo from "../images/logo.png"
import "./home.scss"
import { motion } from "framer-motion"

class Home extends Component {
  render() {
    return (
      <motion.section id="home" initial={{ scale: 0.5 }} animate={{ scale: 0.96 }}>
        <Container fluid>
          <Row>
            <Col sm={12} lg={6} className="text-container">
              <div>
                <h1>Hi, <br/> I'm Jay, <br/> A Software Developer</h1>
              </div>
              <p>Javascript &amp; Blockchain Developer | Cryptocurrency Trader</p>
              <p>Check out the cryptocurrency tracker I built!</p>
              <a href="https://cerberus.saren.io/" rel="noopener noreferrer" target="_blank" className="premium-button">
                Saren.io</a>
            </Col>
            <Col sm={12} md={6} className="image-container">
              <motion.img src={Logo} alt="Jaykch" className="logo" whileHover={{ scale: 1.2 }}/>
            </Col>
          </Row>
        </Container>
      </motion.section>
    )
  }
}

export default Home