import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.scss"
import Home from "../components/home"


const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Javascript & Blockchain Developer"
           description="I build a variety of web applications from e-commerces based on magento for clients with a small time frame to full-stack Blockchain software using React.js and Solidity."/>
      <Home/>
    </Layout>
  )
}

export default IndexPage
